import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { chunk, sortBy, uniqBy } from "lodash";

import { mobileS, laptop } from "../components/breakpoints";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Tags from "../components/tag";

const IndexContainer = styled.div`
    max-width: 80vw;
    margin-right: auto;
    margin-left: auto;
`;

const FeaturedContainer = styled.div`
    @media${mobileS} {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(min-content, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
    @media${laptop} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(min-content, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
    h4 {
        font-size: 32px;
        line-height: 40px;
        margin: 15px 0;
    }
    p {
        line-height: calc(1.25em * 1.5);
    }
`;

const FeaturedSection = styled.div`
    @media${mobileS} {
    }
    @media${laptop} {
        grid-area: 1 / 1 / 2 / 3;
    }
`;
const MoreColumnOne = styled.div`
    @media${mobileS} {
    }
    @media${laptop} {
        grid-area: 1 / 3 / 3 / 4;
    }
`;
const MoreColumnTwo = styled.div`
    @media${mobileS} {
    }
    @media${laptop} {
        grid-area: 1 / 4 / 3 / 5;
    }
`;
const MoreSection = styled.div`
    @media${mobileS} {
    }
    @media${laptop} {
        grid-area: 2 / 1 / 3 / 3;
    }
`;

const RegPostWrapper = styled.div`
    // border-bottom: 2px solid #c33dd4;
    padding: 10px 0;
    h4 {
        font-size: 20px;
        line-height: 24px;
    }
`;

const FeatPostWrapper = styled.article`
    border-bottom: 2px solid #c33dd4;
    padding: 10px 0;
    h4 {
        font-size: 26px;
        line-height: 40px;
    }
`;

const Category = styled.div`
    color: #c33dd4;
    padding: 5px 0;
`;

const CategoryWrapper = styled.section`
    padding-top: 20px;
`;

const CategorySection = styled.div`
    border-top: 2px solid #c33dd4;
    padding: 10px 0;
    margin-bottom: 32px;

    @media${mobileS} {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(min-content, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
    @media${laptop} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(min-content, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }
`;

const SectionHeader = styled.h2`
    font-size: 26px;
    line-height: 40px;
`;

const IndexPage = (res) => {
    const {
        data: {
            featuredPost: {
                edges: [{ node: featuredPost }],
            },
            posts: { edges },
            categories: { nodes: categories },
        },
    } = res;
    const regularPosts = edges.filter(
        ({
            node: {
                frontmatter: { featured },
            },
        }) => !featured
    );

    const FeatPost = () => (
        <FeatPostWrapper>
            <Category>{featuredPost.frontmatter.category}</Category>
            <Link className="plain-link" to={featuredPost.frontmatter.path}>
                <h4>{featuredPost.frontmatter.title}</h4>
            </Link>
            <Link to={featuredPost.frontmatter.path}>
                <Img
                    sizes={{
                        ...featuredPost.frontmatter.featuredImage
                            .childImageSharp.fluid,
                        aspectRatio: 4 / 3,
                    }}
                />
            </Link>
            <p>
                {featuredPost.excerpt}{" "}
                <Link to={featuredPost.frontmatter.path}>Read more</Link>{" "}
            </p>
            <Tags tags={featuredPost.frontmatter.tags} />
        </FeatPostWrapper>
    );

    const nFirstColPosts = 6;
    const [p3, p2] = chunk(
        regularPosts.slice(nFirstColPosts),
        Math.floor(regularPosts.slice(nFirstColPosts).length / 2)
    );

    const p1 = regularPosts.slice(0, nFirstColPosts);

    const maxPostsInColumb = 3;

    const col1 = p1
        .map(({ node: post }) => {
            return (
                <RegPostWrapper>
                    <Category>{post.frontmatter.category}</Category>
                    <Link className="plain-link" to={post.frontmatter.path}>
                        <h4>{post.frontmatter.title}</h4>
                    </Link>
                    <Link to={post.frontmatter.path}>
                        <Img
                            sizes={{
                                ...post.frontmatter.featuredImage
                                    .childImageSharp.fluid,
                                aspectRatio: 4 / 3,
                            }}
                        />
                    </Link>
                    <p>{post.excerpt}</p>
                </RegPostWrapper>
            );
        })
        .slice(0, maxPostsInColumb + 1);
    const col2 = p2
        .map(({ node: post }) => {
            return (
                <RegPostWrapper>
                    <Category>{post.frontmatter.category}</Category>
                    <Link className="plain-link" to={post.frontmatter.path}>
                        <h4>{post.frontmatter.title}</h4>
                    </Link>
                    <Link to={post.frontmatter.path}>
                        <Img
                            sizes={{
                                ...post.frontmatter.featuredImage
                                    .childImageSharp.fluid,
                                aspectRatio: 4 / 3,
                            }}
                        />
                    </Link>
                    <p>{post.excerpt}</p>
                </RegPostWrapper>
            );
        })
        .slice(0, maxPostsInColumb + 1);
    const SubFeatured = p3
        .map(({ node: post }) => {
            return (
                <RegPostWrapper>
                    <Link className="plain-link" to={post.frontmatter.path}>
                        <h4>{post.frontmatter.title}</h4>
                    </Link>
                    <p>{post.excerpt}</p>
                </RegPostWrapper>
            );
        })
        .slice(0, 7);
    const Categories = sortBy(
        uniqBy(categories, (p) => p.frontmatter.category),
        (p) => p.frontmatter.category
    )
        .map((p) => p.frontmatter.category)
        .map((category) => {
            const postsForCategory = edges
                .filter(
                    ({ node: post }) => post.frontmatter.category === category
                )
                .slice(0, 4);
            if (!postsForCategory.length) {
                return null;
            }
            const posts = postsForCategory.map(({ node: post }) => {
                return (
                    <div key={post.id}>
                        <Link to={post.frontmatter.path}>
                            <Img
                                sizes={{
                                    ...post.frontmatter.featuredImage
                                        .childImageSharp.fluid,
                                    aspectRatio: 4 / 3,
                                }}
                            />
                        </Link>
                        <Link className="plain-link" to={post.frontmatter.path}>
                            <h3 style={{ fontSize: 24 }}>
                                {post.frontmatter.title}
                            </h3>
                        </Link>
                    </div>
                );
            });

            return (
                <CategoryWrapper key={category}>
                    <Link className="plain-link" to={`/category/${category}`}>
                        <SectionHeader>{category}</SectionHeader>
                    </Link>
                    <CategorySection>{posts}</CategorySection>
                </CategoryWrapper>
            );
        });
    return (
        <Layout>
            <IndexContainer>
                <SEO title="Home" />
                <FeaturedContainer>
                    <FeaturedSection>
                        <FeatPost></FeatPost>
                    </FeaturedSection>
                    <MoreColumnOne>{col1}</MoreColumnOne>
                    <MoreColumnTwo>{col2}</MoreColumnTwo>
                    <MoreSection>
                        <h3>The Latest</h3>
                        {SubFeatured}
                    </MoreSection>
                </FeaturedContainer>
                {Categories}
            </IndexContainer>
        </Layout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query Posts {
        posts: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        featured
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                        category
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        categories: allMarkdownRemark(limit: 250) {
            nodes {
                frontmatter {
                    category
                }
            }
        }
        featuredPost: allMarkdownRemark(
            filter: { frontmatter: { featured: { eq: true } } }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 700)
                    frontmatter {
                        featured
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                        category
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
